import languageStore from "@reservauto/react-shared/stores/languageStore";
import UrlParams from "@reservauto/react-shared/UrlParams";
import appSettings from "../../../shared/appSettings";

export interface B2CError {
  clear: () => void;
  message: string;
}

interface FormData {
  selector: string;
  value: string;
}

let b2cContainerEl: HTMLElement | null = null;

export function getB2cContainerEl(): HTMLElement {
  if (!b2cContainerEl) {
    b2cContainerEl = document.getElementById("api")!;
  }

  return b2cContainerEl;
}

export function clickB2CButton(selector: string): void {
  const button = getB2cContainerEl().querySelector(
    selector,
  ) as HTMLButtonElement;
  button.click();
}

export function createB2CUrl(
  policy: string,
  redirectUrl?: string,
  additionalParams?: Record<string, string>,
): string {
  const urlParams = new URLSearchParams({
    client_id: new UrlParams().get("client_id") ?? appSettings.B2CClientId,
    nonce: "defaultNonce",
    p: policy,
    redirect_uri: redirectUrl ?? window.location.href,
    response_type: "id_token",
    scope: "openid",
    ui_locales: languageStore.get().twoLetterCode,
    ...additionalParams,
  });

  let urlRoot = appSettings.B2CUri;
  if (isCurrentUrlB2C()) {
    const urlPath = window.location.pathname;
    urlRoot =
      window.location.origin + urlPath.substring(0, urlPath.indexOf("/", 1));
  }

  return `${urlRoot}/oauth2/v2.0/authorize?${urlParams.toString()}`;
}

export function getB2CElement(selector: string): HTMLElement | null {
  return getB2cContainerEl().querySelector(selector) as HTMLElement | null;
}

export function isCurrentUrlB2C(): boolean {
  const b2cUrls = ["p=b2c_", "/b2c_", "/b2c?", "/b2c/", "#error="];
  return b2cUrls.some((url) =>
    window.location.href.toLowerCase().includes(url),
  );
}

export function submitB2CForm(submitSelector: string, data: FormData[]): void {
  for (const dataInfo of data) {
    const inputElements = getB2cContainerEl().querySelectorAll(
      dataInfo.selector,
    );

    if (inputElements.length === 1) {
      const inputElement = inputElements[0] as HTMLInputElement;
      inputElement.value = dataInfo.value;
      inputElement.dispatchEvent(new Event("change", { bubbles: true }));
      inputElement.dispatchEvent(new Event("input", { bubbles: true }));
    } else {
      inputElements.forEach((element) => {
        const inputElement = element as HTMLInputElement;
        if (inputElement.value === dataInfo.value) {
          inputElement.checked = true;
        }
      });
    }
  }

  clickB2CButton(submitSelector);
}
