import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import { FetchPromise } from "@reservauto/react-shared/services/ServiceBase";
import baseBranchStore from "@reservauto/react-shared/stores/baseBranchStore";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import { utcToZonedTime } from "date-fns-tz";
import {
  BranchDetailDTO,
  BranchDTO,
  BranchListDTO,
  EBranch,
  ECity,
} from "../../areas/general/dto";
import appSettings from "../appSettings";

interface BranchDetail extends BranchDetailDTO {
  reservautoLegacyUri: string;
}

const allBranchesLocalStoreKey: StorageKeyInfo<BranchDTO[]> = {
  key: "AllBranches",
  pathIndependant: true,
  ttl: { days: 5 },
  userIndependant: true,
};

export const loginBranchAndCityLocalStoreKey: StorageKeyInfo<{
  branch: EBranch;
  city: ECity;
}> = {
  key: "LoginBranchAndCity",
  pathIndependant: true,
  userIndependant: true,
};

export const defaultBranchDetails: BranchDetail = {
  branch: EBranch.Communauto_Quebec,
  branchAccessRights: null,
  branchId: 1,
  branchName: null,
  culture: "fr-ca",
  currency: "CAD",
  dateFormats: {
    long: "ddd d MMM yyyy",
    medium: "d MMM yyyy",
    short: "dd/MM/yyyy",
  },
  defaultCity: ECity.Montreal,
  reservautoLegacyUri: "",
  timeZone: "America/Toronto",
};

export class BranchStore extends StateStoreBase<BranchDetail> {
  private allBranches: BranchDTO[] = [];
  private branchInfo: BranchDetail = defaultBranchDetails;

  public get(): BranchDetail {
    return this.branchInfo;
  }

  public getAll(): BranchDTO[] {
    return this.allBranches;
  }

  public getAllSorted(): BranchDTO[] {
    return this.allBranches.sort((a, b) =>
      (a.branchName ?? "").localeCompare(b.branchName ?? ""),
    );
  }

  public getById(branchId: EBranch | number): BranchDTO {
    let branch: BranchDTO | undefined;

    if (typeof branchId === "number") {
      branch = this.allBranches.find((b) => b.branchId === branchId);
    } else {
      branch = this.allBranches.find((b) => b.branch === branchId);
    }

    if (!branch) {
      throw new Error(
        `Unknown branchId ${branchId}. Known branches: ${this.allBranches.map((b) => b.branchId).join(", ")}`,
      );
    }

    return branch;
  }

  public getCurrentTimeInBranchTimeZone(): Date {
    return utcToZonedTime(
      // eslint-disable-next-line no-restricted-syntax
      new Date().getTime(),
      this.branchInfo.timeZone ?? "America/Toronto",
    );
  }

  public getTimezone(branchId: EBranch | number): string {
    const branch = this.getById(branchId);
    return branch.timeZone ?? "America/Toronto";
  }

  public populate(branchId: number): void {
    let legacyUrl = appSettings.ReservautoLegacyUris["Branch_" + branchId];
    if (!legacyUrl) {
      legacyUrl = appSettings.ReservautoLegacyUris.Default;
    }

    this.branchInfo = {
      ...this.branchInfo,
      branch: EBranch.Undefined,
      branchId: branchId,
      reservautoLegacyUri: legacyUrl,
    };
    this.notifySubscribers();
  }

  public populateAll(
    getAll: () => FetchPromise<BranchListDTO>,
  ): FetchPromise<BranchDTO[]> {
    let branches = LocalStorageUtil.get(allBranchesLocalStoreKey, null);
    if (
      branches &&
      branches.some(
        (b) => b.defaultCity && b.branchId === this.branchInfo.branchId,
      ) &&
      appSettings.Env !== "Local"
    ) {
      return new FetchPromise<BranchDTO[]>((resolve) => {
        this.allBranches = branches!;
        this.notifySubscribers();

        resolve(branches!);
      });
    } else {
      return getAll().then((result) => {
        branches = result.branches ?? [];
        this.allBranches = branches;
        this.notifySubscribers();
        LocalStorageUtil.set(allBranchesLocalStoreKey, branches);

        return branches;
      });
    }
  }

  public async populateDetails(
    getDetails: (branchId: number) => Promise<BranchDetailDTO>,
  ): Promise<BranchDetailDTO> {
    const branchDetils = await getDetails(this.branchInfo.branchId);

    this.branchInfo = {
      ...this.branchInfo,
      ...branchDetils,
    };
    baseBranchStore.populate({
      currency: branchDetils.currency!,
      id: branchDetils.branchId,
      timeZone: branchDetils.timeZone!,
    });
    this.notifySubscribers();

    return this.branchInfo;
  }
}

const branchStore = new BranchStore();
export default branchStore;
