import { Theme } from "@mui/material/styles";
import themeSizes from "@reservauto/react-shared/ui/theme/themeSizes";
import { CSSObject } from "tss-react";

themeSizes.appBarHeightByBreakpoint = { lg: 50, md: 50, xs: 50 };
themeSizes.checkboxPaddingBySize = { medium: 9, small: 8, tiny: 8 };
themeSizes.contentPaddingHorizontalByBreakpoint = { sm: 20, xs: 16 };
themeSizes.leftNavWidth = 280;
themeSizes.tableCellHorizontalPadding = 40;
themeSizes.topBarHeight = 50;

export function fillContentArea(theme: Theme): CSSObject {
  return {
    "div:has(> &)": {
      minHeight: 0,
      padding: 0,

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  };
}
