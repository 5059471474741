import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import languageStore, {
  Language,
} from "@reservauto/react-shared/stores/languageStore";
import useStateStore from "@reservauto/react-shared/stores/useStateStore";
import makeStyles from "@reservauto/react-shared/ui/theme/makeStyles";
import "fontsource-roboto/300.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";
import "fontsource-roboto/700.css";
import React from "react";
import appSettings from "./shared/appSettings";
import createTheme from "./themes/createTheme";

interface Props {
  children: React.ReactNode;
}

const muiCache = createCache({
  key: "mui",
  nonce: appSettings.Nonce,
  prepend: true,
});

const useStyles = makeStyles()((theme) => ({
  root: {
    "& main": {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      position: "relative",
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));

function Content(props: { children: React.ReactNode }): JSX.Element {
  const { classes } = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}

export default function AppTheme(props: Props): JSX.Element {
  const language = useStateStore<Language>(languageStore);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme(language.materialUI)}>
        <CssBaseline />
        <Content children={props.children} />
      </ThemeProvider>
    </CacheProvider>
  );
}
