import {
  EAvailableFeatureSubcategory,
  EAvailableFeatureType,
} from "../areas/general/dto";
import { LegacyTypes } from "../areas/legacy/legacyRoute";

export const FeatureDivider = "FeaturDivider";

export type FeatureType = EAvailableFeatureType | typeof FeatureDivider;

export interface FeatureInfo {
  legacyType?: LegacyTypes;
  type: FeatureType;
  url: string;
}

export interface FeatureSubcategoryInfo {
  icon: string;
  type: EAvailableFeatureSubcategory;
}

// prettier-ignore
const featuresInfo: FeatureInfo[] = [
  // User
  { legacyType: undefined, type: EAvailableFeatureType.MyAccount, url: "/backOfficeUser/myAccount" },
  { legacyType: undefined, type: EAvailableFeatureType.ReportBug, url: "https://reservauto.atlassian.net/servicedesk/customer/portals" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: undefined, type: EAvailableFeatureType.LogOut, url: "" },
  { legacyType: undefined, type: EAvailableFeatureType.HalloweenMode, url: "" },
  { legacyType: undefined, type: EAvailableFeatureType.ChristmasMode, url: "" },

  // DeveloperPageActions
  { legacyType: undefined, type: EAvailableFeatureType.ChangeLanguage, url: "" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: undefined, type: EAvailableFeatureType.PopOutIframe, url: "" },

  // MainNavigation - Customers
  { legacyType: "legacy",  type: EAvailableFeatureType.BookingManagement, url: "/Scripts/Agent/AbonneDossier.asp?txs=Ini" },
  { legacyType: "legacy",  type: EAvailableFeatureType.RegistryOfSubscribers, url: "/Scripts/Gestion/CustomerSearch.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.MemberInfoInBox, url: "/Scripts/Gestion/CustomerMessage/FollowUp.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PersonalMemos, url: "/Scripts/Agent/Reminder.asp" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.SubscriptionWaitingList, url: "/Scripts/Gestion/Inscriptions/ViewInscription.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.SubscriptionWaitingListNew, url: "/Scripts/Gestion/Subscription/ViewSubscription.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.ReceptionDrivingRecords, url: "/Scripts/Gestion/Inscriptions/DriversRecordsReminder.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Contracts, url: "/Scripts/Gestion/ContractRules/ContractRulesList.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PromotionalOffer, url: "/Scripts/Admin/PromoManager.asp" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.WithdrawalRequestsList, url: "/Scripts/Gestion/Withdrawal/WithdrawalList.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.InformationRequests, url: "/Scripts/Gestion/ContactShowList.asp" },

  // MainNavigation - Cars / Stations
  { legacyType: "legacy",  type: EAvailableFeatureType.FindCar, url: "/Scripts/Agent/CarSearch.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.AdditionsAndModifications, url: "/Scripts/Gestion/StationList.asp?txs=Liste" },
  { legacyType: "legacy",  type: EAvailableFeatureType.AutoBodyDamage, url: "/Scripts/Gestion/CarBoboSelect.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.MaintenanceAndRepairs, url: "/Scripts/Gestion/MaintenancePlanSelect.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.AccidentReports, url: "/Scripts/Gestion/AccidentReport/AccidentReport.aspx" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Mileage, url: "/Scripts/Gestion/GestionKM.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.LsiParkings, url: "/Scripts/Gestion/ParkingProvider/ParkingManager.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.CarMemos, url: "/Scripts/Gestion/CarMemoShowList.asp?Type=1" },
  { legacyType: "legacy",  type: EAvailableFeatureType.StationMemo, url: "/Scripts/Gestion/CarMemoShowList.asp?Type=2" },
  { legacyType: "legacy",  type: EAvailableFeatureType.ParkingProviders, url: "/Scripts/Gestion/ParkingProvider/ParkingProvider.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Zone, url: "/Scripts/Gestion/Zones" },

  // MainNavigation - Interventions
  { legacyType: undefined, type: EAvailableFeatureType.InterventionRequests, url: "/fleet/interventionManager" },
  { legacyType: "legacy",  type: EAvailableFeatureType.CarMap, url: "/Scripts/Gestion/FreeFloating/Map.aspx" },
  { legacyType: undefined, type: EAvailableFeatureType.FleetManager, url: "/fleet/fleetManager" },
  { legacyType: "legacy",  type: EAvailableFeatureType.InterventionOnRoad, url: "/Scripts/Gestion/Interventions/InterventionFollowUp.asp?Type=2" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.RoadInterventions, url: "/Scripts/Gestion/Interventions/RoadInterventions/RoadInterventions.aspx" },

  // MainNavigation - Billing
  { legacyType: "legacy",  type: EAvailableFeatureType.MonthlyBilling, url: "/Scripts/Gestion/BillingMonthly.asp?Exclude0=True" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PackagesAndDeductiblesBilling, url: "/Scripts/Gestion/Billing/PackageBillingManager.asp" },
  { legacyType: "mvc",     type: EAvailableFeatureType.TripsBilling, url: "/ReservationBilling" },
  { legacyType: "legacy",  type: EAvailableFeatureType.ReportingInformation, url: "/Scripts/Gestion/Calendrier.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.UnpaidBalances, url: "/Scripts/Gestion/BalanceShowList.asp" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "mvc",     type: EAvailableFeatureType.Ticket, url: "/Ticket" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Invoices, url: "/Scripts/Gestion/BillingSearch.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.TripsSearch, url: "/Scripts/Gestion/ReservationSearch/Default.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.CalculateTripsInBatch, url: "/Scripts/Gestion/ReservationSearch/Calculate_ReservationsBatch.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.CreditCardWithdrawals, url: "/Scripts/Gestion/Billing/NetBanx/CustomerPayment.aspx" },
  { legacyType: "mvc",     type: EAvailableFeatureType.PaymentsRefunds, url: "/TransactionManager" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.SnowRemoval, url: "/Scripts/Gestion/PackagePrice/SnowRemovalRebate.aspx" },
  { legacyType: "mvc",     type: EAvailableFeatureType.ParkingMeterUsage, url: "/ParkingMeter" },
  { legacyType: "mvc",     type: EAvailableFeatureType.Receipt, url: "/Receipt" },
  { legacyType: undefined, type: EAvailableFeatureType.ReservationBillingReview, url: "/billing/reservationBillingReview" },

  // MainNavigation - Equipment
  { legacyType: "legacy",  type: EAvailableFeatureType.RFIDKey, url: "/Scripts/Gestion/KeySearch.asp?Type=1" },
  { legacyType: "legacy",  type: EAvailableFeatureType.KeyBox, url: "/Scripts/Gestion/KeySearch.asp?Type=2" },
  { legacyType: "legacy",  type: EAvailableFeatureType.OpusCard, url: "/Scripts/Gestion/KeySearch.asp?Type=3" },
  { legacyType: "legacy",  type: EAvailableFeatureType.OnBoardComputersManagement, url: "/Scripts/Gestion/BoardComputerManager/Default.asp" },
  { legacyType: "mvc",     type: EAvailableFeatureType.CreditCard, url: "/CreditCard" },
  { legacyType: undefined, type: EAvailableFeatureType.AnalyzeGasTransactions, url: "/reports/gasCreditCardTransactionAnalysis" },

  // MainNavigation - Public Transport
  { legacyType: "legacy",  type: EAvailableFeatureType.PublicTransportReports, url: "/Scripts/Gestion/PublicTransport/Reports/Report.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PublicTransportImports, url: "/Scripts/Gestion/PublicTransport/Reports/Import.aspx" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PublicTransportPromotions, url: "/Scripts/Gestion/PublicTransport/PublicTransitPromotion.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PublicTransportCustomers, url: "/Scripts/Gestion/PublicTransport/PublicTransitCustomer.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.PublicTransit, url: "/Scripts/Gestion/PublicTransport/PublicTransit.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.OldTransitPrice, url: "/Scripts/Gestion/PublicTransitPrice/Default.asp" },

  // MainNavigation - Tools
  { legacyType: undefined, type: EAvailableFeatureType.CarAccessories, url: "https://www.communauto.com/reservauto/accessoires.html" },
  { legacyType: undefined, type: EAvailableFeatureType.UsefulAddresses, url: "https://www.communauto.com/fr/infos/contrats_formulaires.html" },
  { legacyType: undefined, type: EAvailableFeatureType.RoadsideAssistance, url: "https://www.communauto.com/reservauto/intranet/survoltage.html" },
  { legacyType: undefined, type: EAvailableFeatureType.OnBoardComputers, url: "https://www.communauto.com/abonnes/astus.html" },
  { legacyType: undefined, type: EAvailableFeatureType.UsefulLinks, url: "https://www.communauto.com/abonnes/liens_utiles.html" },
  { legacyType: "legacy",  type: EAvailableFeatureType.FilesList, url: "/Scripts/Agent/FileManager.asp?Section=2" },
  { legacyType: undefined, type: EAvailableFeatureType.AffiliatedRenters, url: "https://www.communauto.com/reservauto/locateurs.html" },
  { legacyType: undefined, type: EAvailableFeatureType.CarDashboards, url: "https://communauto.com/Reservauto/Intranet/vehicules.html" },
  { legacyType: undefined, type: EAvailableFeatureType.RoadsideAssistanceVrtucar, url: "https://www.vrtucar.com/roadside-assistance" },

  // MainNavigation - Administration
  { legacyType: undefined, type: EAvailableFeatureType.UserManagement, url: "/backOfficeUser/userManager" },
  { legacyType: "mvc",     type: EAvailableFeatureType.BranchRights, url: "/AccessRightBranch" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Settings, url: "/Scripts/Admin/SettingsManager.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.Email, url: "/Scripts/Gestion/EmailManager.asp" },
  { legacyType: undefined, type: EAvailableFeatureType.Localization, url: "/labels" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.ScheduleGrid, url: "/Scripts/Gestion/ScheduleManager/ScheduleManager.aspx" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: undefined, type: EAvailableFeatureType.CustomerSection, url: "{legacy}/Scripts/Client/Default.asp?BranchID={branchId}&CurrentLanguageID={languageId}" },
  { legacyType: "legacy",  type: EAvailableFeatureType.WebInscription, url: "/Scripts/Client/Inscription/Default.asp?InscriptionType=2" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionHalifax, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionParis, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionCommunautoQuebec, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionCommunautoOntario, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionCarmine, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionPSA, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },
  { legacyType: undefined, type: EAvailableFeatureType.WebInscriptionCommunautoAlberta, url: "{subscription}/Subscription?branch={branchId}&culture={languageCode}" },

  // MainNavigation - Developer
  { legacyType: undefined, type: EAvailableFeatureType.DemoItems, url: "/developer" },
  { legacyType: "mvc", type: EAvailableFeatureType.MvcDemoItems, url: "/DemoItem" },
  { legacyType: "legacy",  type: EAvailableFeatureType.XmlLabelsGenerator, url: "/Scripts/Developers/Philippe/GenerateXMLLabels.aspx" },
  { legacyType: "legacy",  type: EAvailableFeatureType.EventsValidation, url: "/Scripts/Developers/Philippe/EventsValidationFiles.aspx" },
  { legacyType: undefined, type: FeatureDivider, url: "" },
  { legacyType: "legacy",  type: EAvailableFeatureType.TimeReport, url: "/Scripts/TimeReport/Default.asp" },
  { legacyType: "legacy",  type: EAvailableFeatureType.HangfireDashboard, url: "/Scripts/Admin/hangfire" },
  { legacyType: "legacy",  type: EAvailableFeatureType.SignageParkingOnStreet, url: "/Scripts/Admin/ParkingSigns.aspx" },

  // MainNavigation - OutageRecovery
  { legacyType: "legacy", type: EAvailableFeatureType.FleetWakeUp, url: "/Scripts/Gestion/AstusOutage/FleetWakeUp.aspx" },
  { legacyType: "legacy", type: EAvailableFeatureType.GPSSynchronization, url: "/Scripts/Gestion/AstusOutage/GPSSync.aspx" },
];

export default featuresInfo;

const featureSubcategoriesInfo: FeatureSubcategoryInfo[] = [
  { icon: "icon-users2", type: EAvailableFeatureSubcategory.Customer },
  { icon: "icon-car2", type: EAvailableFeatureSubcategory.CarStation },
  { icon: "icon-map", type: EAvailableFeatureSubcategory.Intervention },
  { icon: "icon-receipt", type: EAvailableFeatureSubcategory.Billing },
  { icon: "icon-key", type: EAvailableFeatureSubcategory.Equipment },
  { icon: "icon-bus2", type: EAvailableFeatureSubcategory.PublicTransport },
  { icon: "icon-hammer-wrench", type: EAvailableFeatureSubcategory.Tool },
  { icon: "icon-cog", type: EAvailableFeatureSubcategory.Administration },
  { icon: "icon-teacup", type: EAvailableFeatureSubcategory.Developer },
  { icon: "icon-first-aid", type: EAvailableFeatureSubcategory.OutageRecovery },
];
export { featureSubcategoriesInfo };
