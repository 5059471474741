import Button from "@mui/material/Button";
import PageProcessingAnimation from "@reservauto/react-shared/frame/PageProcessingAnimation";
import Logging from "@reservauto/react-shared/Logging";
import {
  NetworkRequestFailedError,
  RequestTimeoutError,
} from "@reservauto/react-shared/services/ServiceBase";
import errorStore from "@reservauto/react-shared/stores/errorStore";
import processingStore from "@reservauto/react-shared/stores/processingStore";
import React from "react";
import { createRoot } from "react-dom/client";
import AppTheme from "../AppTheme";
import { LogDetailDTO } from "../areas/general/dto";
import authenticationService from "./authenticationService";
import "./customThemeSizes";
import { ReactComponent as Logo } from "./logo.svg";

export type UserMessage =
  | "B2C"
  | "BrowserCache"
  | "BrowserPlugin"
  | "Connection"
  | "DeviceTime"
  | "Forbidden";

interface Props {
  isAuthenticated?: boolean;
  traceId?: string;
  userMessage?: UserMessage;
}

function CriticalErrorPage(props: Props): JSX.Element | null {
  async function onClickSignOut(): Promise<void> {
    processingStore.pageLoadingStarted();
    if (await authenticationService.getUser()) {
      authenticationService.signoutRedirect().catch((ex) => {
        window.navigateToFatalErrorPage();
      });
    } else {
      authenticationService.signinRedirect().catch((ex) => {
        window.navigateToFatalErrorPage();
      });
    }
  }

  React.useEffect(() => {
    processingStore.pageLoadingStopped();
  }, []);

  let displayMessageEn: string | undefined = undefined;
  let displayMessageFr: string | undefined = undefined;
  switch (props.userMessage) {
    case "BrowserCache":
      displayMessageEn =
        "Could not access the browser cache, please try clearing your browser's cache";
      displayMessageFr =
        "Impossible d'accéder aux données en cache du navigateur. Veuillez essayer de vider le cache de votre navigateur.";
      break;
    case "BrowserPlugin":
      displayMessageEn = "Please disable your browser extensions and try again";
      displayMessageFr =
        "Veuillez désactiver les extensions de votre navigateur et réessayer";
      break;
    case "Connection":
      displayMessageEn =
        "Could not connect, please check your internet connection";
      displayMessageFr =
        "Impossible de se connecter, veuillez vérifier votre connexion Internet";
      break;
    case "DeviceTime":
      displayMessageEn = "Please check the system time of your device";
      displayMessageFr = "Veuillez vérifier l'heure système de votre appareil";
      break;
    case "Forbidden":
      displayMessageEn = "You are not authorized to execute this action";
      displayMessageFr = "Vous n'êtes pas autorisé à effectuer cette action";
      break;
    default:
      if (props.userMessage && window.console) {
        console.error("Unknown message: " + props.userMessage);
      }
      break;
  }

  return (
    <div className="error-page root long">
      <div className="content">
        <Logo />
        <div className="title">Something went wrong here...</div>
        {displayMessageEn ? (
          <div className="message last">{displayMessageEn}</div>
        ) : (
          <React.Fragment>
            <div className="message">
              We are sorry for that technical issue. We'll do our best to get it
              fixed.
            </div>
            <div className="message last">
              Please try reloading the page; it may fix the problem.
            </div>
          </React.Fragment>
        )}
        {props.traceId ? (
          <div className="number">Error #{props.traceId}</div>
        ) : null}
        <div className="links">
          <a href="/">Home</a>
          <a
            href="https://reservauto.atlassian.net/servicedesk/customer/portals"
            rel="noreferrer"
            target="_blank"
          >
            Report a bug
          </a>
        </div>
      </div>
      <br />
      <div className="content">
        <div className="title">Ça ne se passe pas comme prévu...</div>
        {displayMessageFr ? (
          <div className="message last">{displayMessageFr}</div>
        ) : (
          <React.Fragment>
            <div className="message">
              Nous sommes désolés pour ce problème technique. Nous ferons de
              notre mieux pour le régler.
            </div>
            <div className="message last">
              SVP tentez d'actualiser la page ; cela règle parfois le problème.
            </div>
          </React.Fragment>
        )}
        {props.traceId ? (
          <div className="number">Erreur #{props.traceId}</div>
        ) : null}
        <div className="links">
          <a href="/">Accueil</a>
          <a
            href="https://reservauto.atlassian.net/servicedesk/customer/portals"
            rel="noreferrer"
            target="_blank"
          >
            Signaler un incident
          </a>
        </div>
      </div>
      {props.isAuthenticated ? (
        <div>
          <Button onClick={onClickSignOut} size="small">
            Sign out / Quitter
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export async function mount(
  error: unknown,
  userMessage?: UserMessage,
  isAuthenticated?: boolean,
): Promise<void> {
  let displayMessage = userMessage;
  if (
    error instanceof RequestTimeoutError ||
    error instanceof NetworkRequestFailedError
  ) {
    displayMessage = "Connection";
  }

  const warningMessages: UserMessage[] = [
    "B2C",
    "BrowserCache",
    "BrowserPlugin",
    "Connection",
    "DeviceTime",
    "Forbidden",
  ];
  let log: LogDetailDTO | void | undefined = undefined;
  try {
    if (displayMessage && warningMessages.includes(displayMessage)) {
      await Logging.warning(error);
    } else {
      log = await Logging.error(error);
    }
  } catch (ex) {
    if (window.console) {
      console.error(ex);
    }
  }

  const iframe = document.getElementById("legacyIframe");
  if (iframe) {
    iframe.remove();
  }

  try {
    const root = createRoot(document.getElementById("root")!);
    root.render(
      <React.StrictMode>
        <AppTheme>
          <main>
            <PageProcessingAnimation appBarMode="top-only" />
            <CriticalErrorPage
              isAuthenticated={isAuthenticated}
              traceId={errorStore.getTraceId(error, log)}
              userMessage={displayMessage}
            />
          </main>
        </AppTheme>
      </React.StrictMode>,
    );
  } catch (ex) {
    Logging.error(ex).finally(() => {
      window.navigateToFatalErrorPage();
    });
  }
}
